import Environments from "../components/content/environments.json";
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
interface LandingViewProps {
  _cb: (x?: string) => void;
}

const LandingView = (props: LandingViewProps) => {
  return (
    <div style={{ width: "80%", margin: "auto" }}>
      <img src="/codeplant_images/logo_notext.png" width="150" />
      <h1 style={{ color: "#118C38" }}>Welcome to Codeplant!</h1>
      <div style={{ width: "80%", textAlign: "justify", margin: "auto" }}>
        <p>
          Codeplant is a dynamic educational platform focused on equipping
          learners with essential skills for the future of work. By integrating
          Artificial Intelligence, Game Theory, and cutting-edge research,
          Codeplant offers a unique learning experience that prepares
          individuals for success in an ever-evolving workplace.
        </p>
      </div>
      <br />
      <Fieldset
        legend="Codeplant Mission"
        style={{ width: "100%", margin: "auto", textAlign: "justify" }}
      >
        <h1 style={{ textAlign: "center", color: "#118C38" }}>
          Codeplant inspires young innovators — from middle school students to
          young adults — to use technology creatively and responsibility for
          positive change.
        </h1>
      </Fieldset>
      <br />
      <Fieldset
        legend="Codeplant Learning Environments"
        style={{ width: "80%", margin: "auto", textAlign: "justify" }}
      >
        <p>
          The Codeplant platform offers a diverse collection of engaging,
          interactive simulations that break down advanced technological
          concepts, making them accessible and easy to grasp. Explore the
          different environments below, or on the right side of this page, to
          learn more about each one in detail.
        </p>

        <div
          style={{
            width: "100%",
            margin: "auto",
            display: "flex",
            textAlign: "center",
          }}
        >
          <Tooltip target=".target-icon" style={{ maxWidth: "400px" }} />
          {Environments.map((environment) => {
            return (
              <div
                key={environment.id}
                className="target-icon"
                data-pr-tooltip={environment.shortDescription}
                data-pr-position="top"
                style={{ margin: "10px" }}
              >
                <img
                  onClick={() => {
                    props._cb(environment.slug);
                  }}
                  key={environment.id}
                  src={environment.images[0]}
                  alt="Card"
                  style={{ height: "50px" }}
                />
              </div>
            );
          })}
        </div>
      </Fieldset>
      <br />
      <Fieldset
        legend="Codeplant AI Club"
        style={{ width: "100%", margin: "auto", textAlign: "justify" }}
      >
        <p>
          Codeplant AI Club is a community of young innovators who meet online
          weekly to collaborate on technology projects that make a positive
          impact on the world. The club combines learning with hands-on creation
          in a dynamic tech lab environment. Live sessions are led by
          professional technologists who bring both passion and expertise to the
          field.
        </p>
        <Button
          icon="pi pi-info-circle"
          label="Find out More"
          onClick={() => {
            props._cb("ai-club");
          }}
        />
      </Fieldset>
    </div>
  );
};

export default LandingView;
