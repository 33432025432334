import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Panel } from "primereact/panel";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useState } from "react";
import Environments from "../components/content/environments.json";
import { Fieldset } from "primereact/fieldset";
const skills = [
  { id: 1, name: "Analytical thinking", icon: "pi pi-chart-bar" },
  { id: 2, name: "Creative thinking", icon: "pi pi-pencil" },
  { id: 3, name: "Resilience, flexibility and agility", icon: "pi pi-refresh" },
  { id: 4, name: "Motivation and self-awareness", icon: "pi pi-bolt" },
  { id: 5, name: "Curiosity and lifelong learning", icon: "pi pi-book" },
  { id: 6, name: "Technological literacy", icon: "pi pi-desktop" },
  {
    id: 7,
    name: "Dependability and attention to detail",
    icon: "pi pi-check-circle",
  },
  { id: 8, name: "Empathy and active listening", icon: "pi pi-comments" },
  { id: 9, name: "Leadership and social influence", icon: "pi pi-users" },
  { id: 10, name: "Quality control", icon: "pi pi-list" },
  { id: 11, name: "AI literacy", icon: "pi pi-microchip-ai" },
];

interface Environments {
  id: string;
  title: string;
  shortDescription: string;
  longDescription: string[];
  learningObjectives: string[];
  images: string[];
  url: string;
  screenshot: string;
}

type Props = {
  environment: Environments;
  _homeCb: (s?: string) => void;
  _environmentCb: (x: string) => void;
};

const LearningEnvironments = (props: Props) => {
  const [_skills, setSkills] = useState([]);
  // a function to find a skill in the skils array by name
  const findSkill = (name: string) => {
    return skills.find((skill) => skill.name === name);
  };

  useEffect(() => {
    // get the skills that are in the learning objectives
    const skills_ = props.environment.learningObjectives.map((word) => {
      const skill = findSkill(word);
      if (skill) {
        return skill;
      }
    });

    setSkills(skills_ as any);
  }, [props]);

  const _footer = (item: any) => {
    return (
      <div style={{ textAlign: "justify" }}>
        <p>{item.shortDescription}</p>
        {item.hasOwnProperty("freeAccess") && item.freeAccess === true ? (
          <Button
            label={"Click for Preview Access"}
            icon="pi pi-arrow-right"
            style={{ width: "100%" }}
            onClick={() => window.open(item.url)}
          />
        ) : (
          <Button
            label={"Join AI Club to Access"}
            icon="pi pi-arrow-right"
            style={{ width: "100%" }}
            onClick={() => {
              props._homeCb("sign-up");
            }}
          />
        )}
      </div>
    );
  };
  return (
    <>
      <div style={{ width: "80%", margin: "auto", display: "flex" }}>
        <Panel
          style={{
            width: "25%",
            textAlign: "left",
            marginRight: "20px",
            padding: "3px",
          }}
          header={props.environment.title}
        >
          <img
            src={props.environment.images[0]}
            alt="Card"
            style={{ width: "100%", marginBottom: "10px" }}
          />
          {_footer(props.environment)}

          <br />
        </Panel>
        <div style={{ width: "75%", paddingTop: "10px" }}>
          <div style={{ width: "75%", margin: "auto", textAlign: "justify" }}>
            <img
              src={props.environment.screenshot}
              style={{
                borderRadius: "15px",
                width: "100%",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            />
            {props.environment.longDescription.map((eachOne: string) => {
              return <p>{eachOne}</p>;
            })}
          </div>
          <div style={{ width: "75%", margin: "auto", textAlign: "justify" }}>
            <hr />
          </div>
          <br />
          <h4>Learning Objectives</h4>
          <div className="skills-grid">
            {_skills.length > 0 &&
              _skills.map((skill: any) => (
                <Card key={skill.id} className="skill-card-sm">
                  <i className={skill.icon}></i>
                  <br />
                  <span>{skill.name}</span>
                </Card>
              ))}
          </div>

          <br />
          <Card style={{ width: "100%", textAlign: "center" }}>
            <p>
              The Codeplant platform offers a diverse collection of engaging,
              interactive simulations that break down advanced technological
              concepts, making them accessible and easy to grasp. Explore the
              different environments on the right side of this page, or below,
              to learn more about each one in detail.
            </p>
            <Tooltip target=".target-icon" style={{ maxWidth: "400px" }} />

            <div
              style={{ width: "100%", display: "flex", textAlign: "center" }}
            >
              {Environments.map((environment) => {
                return (
                  <div
                    className="target-icon"
                    data-pr-tooltip={environment.shortDescription}
                    data-pr-position="top"
                    style={{ margin: "auto" }}
                  >
                    <img
                      onClick={() => {
                        props._environmentCb(environment.slug);
                      }}
                      key={environment.id}
                      src={environment.images[0]}
                      alt="Card"
                      style={{ height: "50px" }}
                    />
                  </div>
                );
              })}
            </div>
          </Card>

          <Button
            label="Home"
            icon="pi pi-arrow-left"
            onClick={() => {
              props._homeCb();
            }}
            style={{ width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

export default LearningEnvironments;
