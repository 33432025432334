import React from "react";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import WEF_Future_of_Jobs_Report from "./content/jobs_2025.json";
import FutureSkillsChart from "./future-skills";
import { Fieldset } from "primereact/fieldset";
interface Subsection {
  title: string;
  content: string;
}

interface Section {
  title: string;
  content?: string;
  subsections?: Subsection[];
}

interface ReportData {
  report: {
    title: string;
    source: string;
    link: string;
    sections: Section[];
  };
}

const FutureOfJobsReport2025: React.FC = () => {
  const report = WEF_Future_of_Jobs_Report.report as any;
  return (
    <>
      <Card
        title={
          <p style={{ color: "#118c38" }}>
            <i className="pi pi-book"></i>&nbsp;&nbsp;{report.title}
          </p>
        }
        subTitle={report.source}
      >
        <FutureSkillsChart />
        <p>
          <Button
            label="Read Full Report"
            icon="pi pi-external-link"
            onClick={() => window.open(report.link, "_blank")}
          />
        </p>
      </Card>

      {report.sections.map((section: any, index: number) => (
        <Fieldset legend={<h4>{section.title}</h4>} style={{ margin: "20px" }}>
          {section.content && (
            <p style={{ width: "75%", margin: "auto", textAlign: "justify" }}>
              {section.content}
            </p>
          )}
          {section.subsections &&
            section.subsections.map((subsection: any, subIndex: number) => (
              <div key={subIndex} className="p-mb-3">
                <h4>{subsection.title}</h4>
                <p
                  style={{ width: "75%", margin: "auto", textAlign: "justify" }}
                >
                  {subsection.content}
                </p>
              </div>
            ))}
        </Fieldset>
      ))}
    </>
  );
};

export default FutureOfJobsReport2025;
