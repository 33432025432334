import React, { useEffect, useState } from "react";
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Card } from "primereact/card";
import { useMediaQuery } from "react-responsive";
import { Panel } from "primereact/panel";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

interface Video {
  id: string;
  title: string;
  url: string;
}

interface VideoGalleryProps {
  videos: Video[];
}

const VideoGallery: React.FC<VideoGalleryProps> = ({ videos }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const videosPerPage = 1;
  const [filteredVideos, setFilteredVideos] = useState<Video[]>(videos);
  const [currentVideos, setCurrentVideos] = useState<Video[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const isLargeScreen = useMediaQuery({ minWidth: 768 });

  const totalPages = Math.ceil(filteredVideos.length / videosPerPage);

  useEffect(() => {
    const indexOfLastVideo = currentPage * videosPerPage;
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
    setCurrentVideos(filteredVideos.slice(indexOfFirstVideo, indexOfLastVideo));
  }, [currentPage, filteredVideos]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    const lowerCaseQuery = query.toLowerCase();
    const filtered = videos.filter((video) =>
      video.title.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredVideos(filtered);
    setCurrentPage(1); // Reset to the first page when the search query changes
  };

  const renderGridItem = (video: Video) => (
    <div
      className="p-col-12 p-md-4"
      key={video.id}
      style={{ marginBottom: "20px", textAlign: "left" }}
    >
      <div style={{ color: "black", fontWeight: "bold" }}>
        <p>
          <i className="pi pi-youtube"></i>&nbsp;&nbsp;{video.title}{" "}
        </p>
        <div className="video-container">
          <iframe
            width="100%"
            src={video.url}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );

  const renderListItem = (video: Video) => (
    <div className="p-col-12" key={video.id}>
      <Card>
        <div className="video-container">
          <iframe
            width="100%"
            height="200"
            src={`https://www.youtube.com/embed/${video.url}`}
            title={video.title}
            allowFullScreen
          />
        </div>
      </Card>
    </div>
  );

  const headerTemplate = () => (
    <div className="panelHeader">
      <i className="pi pi-video" style={{ fontSize: 25, color: "white" }}></i>
      &nbsp;&nbsp;VIDEOS
    </div>
  );

  return (
    <Panel headerTemplate={headerTemplate()}>
      <div className="p-inputgroup" style={{ marginBottom: "20px" }}>
        <span className="p-inputgroup-addon">
          <i className="pi pi-search" />
        </span>
        <InputText
          placeholder="Search videos..."
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <ScrollPanel style={{ height: "400px", overflowY: "scroll" }}>
        <DataView
          value={currentVideos}
          layout={isLargeScreen ? "grid" : "list"}
          itemTemplate={isLargeScreen ? renderGridItem : renderListItem}
        />
        <div className="pagination-controls">
          <Button
            icon="pi pi-arrow-left"
            outlined
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          />
          <span style={{ margin: 15 }}>
            Page {currentPage} of {totalPages}
          </span>
          <Button
            icon="pi pi-arrow-right"
            outlined
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          />
        </div>
      </ScrollPanel>
    </Panel>
  );
};

export default VideoGallery;
